import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';

import Loadable from 'components/Loadable';

const Orders = Loadable(lazy(() => import('pages/orders/create')));
const EditOrder = Loadable(lazy(() => import('pages/orders/edit')));
const Cart = Loadable(lazy(() => import('pages/apps/e-commerce/checkout.js')));
const PaymentSuccess = Loadable(lazy(() => import('pages/apps/payment-success.js')));
const PagesLanding = Loadable(lazy(() => import('pages/landing')));

const MainRoutes = {
  path: '/',
  exact: true,
  element: <MainLayout />,
  isPublic: true,
  childrenRoutes: [
    {
      path: '/dashboard',
      exact: true,
      element: <PagesLanding />,
      isPublic: true
    },
    {
      path: '/payment-success',
      exact: true,
      element: <PaymentSuccess />,
      isPublic: true
    },
    {
      path: 'cart',
      exact: true,
      element: <Cart />,
      isPublic: true
    },
    {
      path: 'orders',
      exact: true,
      element: <Orders />,
      isPublic: true
    },
    {
      path: 'orders/:id/edit',
      exact: true,
      element: <EditOrder />,
      isPublic: true
    }
  ]
};

export default MainRoutes;
