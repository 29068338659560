import { useMemo } from 'react';

import { Box } from '@mui/material';

import Profile from './Profile';
import Localization from './Localization';

import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';

const HeaderContent = () => {
  const localization = useMemo(() => <Localization />, []);

  return (
    <>
      <DrawerHeader open={true} />
      <Box sx={{ width: '100%', ml: 1 }} />
      {localization}
      <Profile />
    </>
  );
};

export default HeaderContent;
