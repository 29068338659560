// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentText, Profile, ShoppingCart, Card } from 'iconsax-react';

// icons
const icons = {
  card: Card,
  document: DocumentText,
  profile: Profile,
  ecommerce: ShoppingCart
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const auth = {
  id: 'group-applications',
  title: <FormattedMessage id=" " />,
  icon: icons.applications,
  type: 'group',
  children: [
    {
      id: 'profile',
      title: 'rovi_view_profile',
      type: 'item',
      icon: icons?.profile,
      url: '/profile'
    },
    {
      id: 'e-commerce',
      title: 'rovi_my_orders',
      type: 'item',
      icon: icons?.document,
      url: '/my-orders'
    },
    {
      id: 'vignettes/expired',
      title: 'rovi_vignettes_status',
      type: 'item',
      icon: icons?.card,
      url: '/vignettes/expired'
    },
    {
      id: 'order',
      title: 'rovi_orders_label',
      type: 'item',
      icon: icons?.card,
      url: '/orders'
    },
    {
      id: 'cart',
      title: 'rovi_cart_label',
      type: 'item',
      icon: icons?.ecommerce,
      url: '/cart'
    }
  ]
};

const authMenuItems = {
  items: [auth]
};

export default authMenuItems;
