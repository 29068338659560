import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

import { ReactComponent as RovinieteLogo } from 'assets/images/Roviniete.Online.svg';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ reverse, isIcon, sx, to }) => (
  <ButtonBase disableRipple component={Link} to='/orders' sx={sx}>
    {isIcon ? <RovinieteLogo style={{ width: '160px' }} /> : <RovinieteLogo style={{ width: '160px' }} />}
  </ButtonBase>
);

LogoSection.propTypes = {
  reverse: PropTypes.bool,
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
