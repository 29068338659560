import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Card, Profile, ShoppingCart, DocumentText } from 'iconsax-react';

import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

const ProfileTab = ({ user }) => {
  const { t } = useTranslation();
  const navigateTo = useNavigate();
  const location = window.location.href.split('/')[3];
  const [selectedIndex, setSelectedIndex] = useState(location);

  const handleListItemClick = (direct) => {
    navigateTo(`/${direct}`);
    setSelectedIndex(direct);
  };

  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
      {user
        ? <>
          <ListItemButton selected={selectedIndex === 'profile'} onClick={() => handleListItemClick('profile')}>
            <ListItemIcon>
              <Profile variant="Bulk" size={18} />
            </ListItemIcon>
            <ListItemText primary={t('rovi_view_profile')} />
          </ListItemButton>
          <ListItemButton selected={selectedIndex === 'my-orders'} onClick={() => handleListItemClick('my-orders')}>
            <ListItemIcon>
              <DocumentText variant="Bulk" size={18} />
            </ListItemIcon>
            <ListItemText primary={t('rovi_my_orders')} />
          </ListItemButton>
          <ListItemButton selected={selectedIndex === 'vignettes/expired'} onClick={() => handleListItemClick('vignettes/expired')}>
            <ListItemIcon>
              <DocumentText variant="Bulk" size={18} />
            </ListItemIcon>
            <ListItemText primary={t('rovi_expired_vignettes')} />
          </ListItemButton>
        </>
        : <ListItemButton selected={selectedIndex === 'authentication'} onClick={() => handleListItemClick('authentication')}>
          <ListItemIcon>
            <Profile variant="Bulk" size={18} />
          </ListItemIcon>
          <ListItemText primary={t('rovi_authentication_label')} />
        </ListItemButton>
      }
      <ListItemButton selected={selectedIndex === 'orders'} onClick={() => handleListItemClick('orders')}>
        <ListItemIcon>
          <Card variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t('rovi_orders_label')} />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 'cart'} onClick={() => handleListItemClick('cart')}>
        <ListItemIcon>
          <ShoppingCart variant="Bulk" size={18} />
        </ListItemIcon>
        <ListItemText primary={t('rovi_cart_label')} />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func,
  user: PropTypes.object
};

export default ProfileTab;
