import useSWR from 'swr';
import { Route, Routes, Navigate } from 'react-router';
import { useDispatch } from 'react-redux';

import { setUser } from 'store/security';

import Loader from 'components/Loader';

import ThemeCustomization from 'themes';

import { routes } from './routes';

const App = () => {
  const { data: me, error } = useSWR(`${process.env.REACT_APP_VIGNETTE_SERVICE_URL}/auth/me`, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 60000 * 10 // every 10 minutes
  });

  const dispatch = useDispatch();

  if (!me && !error) {
    return <Loader />;
  }

  if (me) {
    dispatch(setUser(me));
  }

  return (
    <ThemeCustomization>
      <Routes>
        {routes.map((props, index) => {
          if (props.childrenRoutes && props.childrenRoutes.length > 0) {
            return <Route key={index} {...props}>
              {props.childrenRoutes.map((childrenProps, index) => {
                if (childrenProps.isPublic) {
                  return <Route key={index} path={childrenProps.path} {...childrenProps} />;
                }

                return (me)
                  ? <Route key={index} path={childrenProps.path} {...childrenProps} />
                  : <Route key={index} path={childrenProps.path} element={<Navigate to='/forbidden' />} />;
              }
              )}
            </Route>;
          }

          return <Route key={index} {...props} />;
        })}
      </Routes>
    </ThemeCustomization>
  );
};

export default App;
