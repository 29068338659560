import App from './App';

import { SWRConfig } from 'swr';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import 'assets/fonts/inter/inter.css';

import 'utils/translations';
import axios from 'utils/axios';

import { store, persistor } from 'store';


const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <SWRConfig value={{ fetcher: (resource, init) => axios.get(resource, init).then(res => res.data) }}>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </SWRConfig>
  </StrictMode>
);