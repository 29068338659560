import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers } from 'redux';
import { useSelector as useAppSelector } from 'react-redux';
import storage from 'redux-persist/lib/storage';

import security from 'store/security';
import cartReducer from 'store/cart';
import product from 'store/product';
import menu from 'store/menu';

const reducer = combineReducers({
  security,
  cart: persistReducer(
    {
      key: 'cart',
      storage,
      keyPrefix: 'able-pro-material-ts-'
    },
    cartReducer
  ),
  menu,
  product
});

const store = configureStore({ reducer });
const persistor = persistStore(store);

const { dispatch } = store;
const useSelector = useAppSelector;

export { dispatch, useSelector, store, persistor };