import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';

import Loadable from 'components/Loadable';

const Login = Loadable(lazy(() => import('pages/auth/auth1/login')));
const Register = Loadable(lazy(() => import('pages/auth/auth1/register')));
const ForgotPassword = Loadable(lazy(() => import('pages/auth/auth1/forgot-password')));
const ResetPassword = Loadable(lazy(() => import('pages/auth/auth1/reset-password')));
const CheckEmail = Loadable(lazy(() => import('pages/auth/auth1/check-mail')));
const Confirmation = Loadable(lazy(() => import('pages/auth/auth1/confirmation')));

const LoginRoutes = {
  path: '/',
  exact: true,
  element: <MainLayout />,
  isPublic: true,
  childrenRoutes: [
    {
      path: '/authentication',
      exact: true,
      element: <Login />,
      isPublic: true
    },
    {
      path: '/register',
      exact: true,
      element: <Register />,
      isPublic: true
    },
    {
      path: 'forgot-password',
      exact: true,
      element: <ForgotPassword />,
      isPublic: true
    },
    {
      path: 'reset-password',
      exact: true,
      element: <ResetPassword />,
      isPublic: true
    },
    {
      path: 'check-email',
      exact: true,
      element: <CheckEmail />,
      isPublic: true
    },
    {
      path: 'confirmation',
      exact: true,
      element: <Confirmation />,
      isPublic: true
    },
    {
      path: 'forbidden',
      exact: true,
      element: <p >FORBIDDEN!</p>,
      isPublic: true
    }
  ]
};

export default LoginRoutes;
