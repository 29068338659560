// material-ui
import { CardMedia, Grid, Link, Stack, Tooltip, Typography } from '@mui/material';

import { motion } from 'framer-motion';

// assets
import Romania from 'assets/images/romania.svg';
import Cnair from 'assets/images/CNAIR.svg';

// ==============================|| MAIN LAYOUT - FOOTER ||============================== //

const Footer = () => (
  <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ p: '24px 16px 0px', mt: 'auto' }}>
    <Typography variant="caption">&copy; Roviniete.online ♥</Typography>
    <Stack spacing={1.5} direction="row" justifyContent="space-between" alignItems="center">
      <Grid item>
        <motion.div
          initial={{ opacity: 0, translateY: 550 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 30,
            delay: 1.1
          }}
        >
          <Tooltip title="Romania">
            <Link component={Link}>
              <CardMedia component="img" image={Romania} sx={{ width: 'auto', height: { xs: 20, md: 25 }}} />
            </Link>
          </Tooltip>
        </motion.div>
      </Grid>
      <Grid item>
        <motion.div
          initial={{ opacity: 0, translateY: 550 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{
            type: 'spring',
            stiffness: 150,
            damping: 30,
            delay: 1.1
          }}
        >
          <Tooltip title="CNAIR">
            <Link component={Link}>
              <CardMedia component="img" image={Cnair} sx={{ width: 'auto', height: { xs: 25, md: 30 }}} />
            </Link>
          </Tooltip>
        </motion.div>
      </Grid>
    </Stack>
  </Stack>
);

export default Footer;
