import axios from 'axios';
import Agent from 'agentkeepalive';

const httpAgent = new Agent({
  timeout: 60000,
  keepAlive: true,
  maxSockets: 100,
  maxFreeSockets: 10,
  withCredentials: true,
  credentials: 'include',
  freeSocketTimeout: 30000
});

axios.defaults.withCredentials = true;

const instance = axios.create({ httpAgent });

instance.interceptors.response.use(
  response => response,
  error => {
    return Promise.reject({ status: error.response.status, data: error.response.data });
  }
);

export default instance;
