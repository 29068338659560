import { lazy } from 'react';

import Loadable from 'components/Loadable';

import MainLayout from 'layout/MainLayout';

const AccountDetails = Loadable(lazy(() => import('pages/account')));
const List = Loadable(lazy(() => import('pages/apps/invoice/list')));
const VignettesList = Loadable(lazy(() => import('pages/apps/vignettes/list')));
const Details = Loadable(lazy(() => import('pages/apps/invoice/details')));

const UserRoutes = {
  path: '/',
  exact: true,
  element: <MainLayout />,
  isPublic: false,
  childrenRoutes: [
    {
      path: '/profile',
      exact: true,
      element: <AccountDetails />,
      isPublic: false
    },
    {
      path: 'my-orders',
      exact: true,
      element: <List />,
      isPublic: false
    },
    {
      path: 'my-orders/:id/details',
      exact: true,
      element: <Details />,
      isPublic: false
    },
    {
      path: 'vignettes/expired',
      exact: true,
      element: <VignettesList />,
      isPublic: false
    }
  ]
};

export default UserRoutes;
