import { CircularProgress, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh'
  }
}));

const LoadingSpinner = () => {
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.container}>
        <CircularProgress />
      </Container>
    </div>
  );
};

export default LoadingSpinner;
