// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { Card, ShoppingCart, Profile } from 'iconsax-react';

// icons
const icons = {
  cart: ShoppingCart,
  profile: Profile,
  ecommerce: Card
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id=" " />,
  icon: icons.applications,
  type: 'group',
  children: [
    {
      id: 'profile',
      title: 'rovi_authentication_label',
      type: 'item',
      icon: icons?.profile,
      url: '/authentication'
    },
    {
      id: 'e-commerce',
      title: 'rovi_orders_label',
      type: 'item',
      icon: icons?.ecommerce,
      url: '/orders'
    },
    {
      id: 'customer',
      title: 'rovi_cart_label',
      type: 'item',
      icon: icons?.cart,
      url: '/cart'
    }
  ]
};

export default applications;
